import React from 'react'

import { Helmet } from 'react-helmet'

import SolidButton from '../components/solid-button'
import PlaceCard from '../components/place-card'
import './landing-page.css'

const LandingPage = (props) => {
  return (
    <div className="landing-page-container">
      <Helmet>
        <title>DenmarkFahrradTour</title>
        <meta property="og:title" content="DenmarkFahrradTour" />
      </Helmet>
      <div className="landing-page-top-container">
        <nav data-role="Header" className="landing-page-navbar">
          <h1 className="landing-page-logo">
            Anil &amp; Judys epische Dänemark Fahrradtour
          </h1>
          <div className="landing-page-right-side"></div>
          <a href="#unterkunft" className="landing-page-link button">
            Unterkünfte
          </a>
          <div data-type="BurgerMenu" className="landing-page-burger-menu">
            <svg viewBox="0 0 1024 1024" className="landing-page-burger-menu">
              <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            </svg>
          </div>
          <div data-type="MobileMenu" className="landing-page-mobile-menu">
            <div className="landing-page-nav">
              <div className="landing-page-top">
                <h1>Travel</h1>
                <div
                  data-type="CloseMobileMenu"
                  className="landing-page-close-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="landing-page-icon03">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="landing-page-right-side1">
                <div className="landing-page-links-container">
                  <span className="landing-page-text">Home</span>
                  <span className="landing-page-text01">About</span>
                  <span className="landing-page-text02">Tour Packages</span>
                  <span>Contact</span>
                </div>
                <a href="#main-section" className="landing-page-link01">
                  <SolidButton button="Explore places"></SolidButton>
                </a>
              </div>
            </div>
            <div className="landing-page-follow-container">
              <span className="landing-page-text04">
                Follow us on
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <div className="landing-page-icons-container">
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="landing-page-link02"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="landing-page-icon05"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="landing-page-link03"
                >
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="landing-page-icon07"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="landing-page-link04"
                >
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="landing-page-icon09"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <a href="#faehre" className="landing-page-link05 button">
            Fähren
          </a>
          <a href="#anfahrt" className="landing-page-link06 button">
            Anreise &amp; Rückfahrt
          </a>
        </nav>
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1MHbRmxgjCGFtSfKw4DEoYLw_rJ6EVVY&amp;hl=de&amp;ehbc=2E312F"
          className="landing-page-iframe"
        ></iframe>
      </div>
      <div id="main-section" className="landing-page-main">
        <h1 className="landing-page-text05">
          <span>
            Fahrradtour Details - Auf den Spuren von
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://en.eurovelo.com/ev10"
            target="_blank"
            rel="noreferrer noopener"
            className="landing-page-link07"
          >
            EuroVelo 10
          </a>
        </h1>
        <span className="landing-page-text07">
          2 Fahrräder ... 7 Tage Tour ... 4 Fähren ... 2 Verrückte ... 10 Tage
          Auszeit vom Alltag
        </span>
        <span className="landing-page-text08">
          <br></br>
          <br></br>
          <span>
            Da wir ja jetzt einen super Fahrradträger für das Autodach haben,
            starten wir unsere Reise mit einem Roadtrip nach Hamburg. Dort
            übernachten wir eine Nacht, um dann am nächsten Morgen frisch und
            ausgeruht nach Flensburg weiterzufahren. Dort beginnt unsere
            Dänemark-Fahrradtour.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>
            Direkt am zweiten Tag unseres Ausflugs starten wir mit einer ca.
            74km-Tour über die deutsch-dänische Landesgrenze, an dessen Ende uns
            schon die erste Fahrt mit der Fähre erwartet, immer mit Sicht auf
            die kühle Ostsee. In Søby erwartet uns eine Hütte auf dem
            Campingplatz, um uns von unserer ersten Tour zu erholen.
          </span>
          <br></br>
          <span>
            Am nächsten Tag geht es weiter nach Ærøskøbing, wo wir mit der Fähre
            nach Svendborg übersetzen, um dann unsere Reise nach Spodsbjerg
            fortzusetzen. Auf dem Weg fahren wir über eine 5km lange Brücke
            direkt über das Meer. 
          </span>
          <br></br>
          <span>
            Am 4. Tag unseres Ausflugs nehmen wir die nächste Fähre von
            Spodsbjerg nach Tårs, um von dort immer am Meer entlang nach Rødby
            Sogn zu fahren. 
          </span>
          <br></br>
          <span>
            Am darauffolgenden Tag geht die Fahrradtour weiter durch das
            Landesinnere bis nach Stubbekøbing, von wo die Fähre uns nach Bogø
            By und unserer nächsten Übernachtungsmöglichkeit bringt.
          </span>
          <br></br>
          <span>
            Von hier fahren wir weiter nach Præstø, wieder am Meer entlang und
            überqueren dabei einige kilometerlange Brücken direkt über das Meer.
          </span>
          <br></br>
          <span>
            Unser 6. Tag der Fahrradtour bringt uns nach Køge und damit unserem
            finalen Ziel, Kopenhagen immer näher. Auf dem Weg können wir wieder
            ausgiebig den Meeresblick und den kühlen Ostseewind genießen.
          </span>
          <br></br>
          <span>
            Am letzten Tag unserer Fahrradtour führt uns der EuroVelo10 direkt
            an der Køge Bucht entlang bis nach Kopenhagen. Dort können wir noch
            ein, zwei Tage die Stadt erkunden und unsere müden Glieder ausruhen,
            bevor wir unsere Fahrräder in den Zug einladen und ca. 3 Std. vom
            Hauptbahnhof Kopenhagen nach Flensburg fahren.
          </span>
          <br></br>
          <span>
            Auf unserer Rückreise nach München machen wir einen Zwischenstop in
            Hannover, von wo wir in ca. 6 Std 30 min zurück in München sind.
          </span>
          <br></br>
        </span>
        <div className="landing-page-container01"></div>
        <div className="landing-page-tour-details-container">
          <h1 id="unterkunft" className="landing-page-text29">
            Unterkünfte
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </h1>
          <div className="landing-page-container02">
            <a
              href="https://soeby-camping.dk/priser/"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link08"
            >
              <PlaceCard
                city="Søby"
                image="https://soeby-camping.dk/wp-content/uploads/2013-05-17-14.20.02-380x380.jpg"
                description="Søby ist die nördlichste Stadt auf der dänischen Insel Ærø. Die Stadt bildet eine eigene Kirchspielsgemeinde Søby Sogn und gehört zur Ærø Kommune in der Region Syddanmark."
                rootClassName="place-card-root-class-name"
                className="landing-page-component01"
              ></PlaceCard>
            </a>
            <a
              href="https://de.wikipedia.org/wiki/S%C3%B8by_(%C3%86r%C3%B8_Kommune)"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link09 button"
            >
              Infos
            </a>
          </div>
          <div className="landing-page-container03">
            <div className="landing-page-container04">
              <a
                href="https://www.spodsbjerg.dk/lejligheder-og-hytter?lang=en"
                target="_blank"
                rel="noreferrer noopener"
                className="landing-page-link10"
              >
                <PlaceCard
                  city="Spodsbjerg"
                  image="https://www.beachrex.com/img/beaches_mobile/rudkobing-strand-spodsbjerg_5/rudkobing-strand-spodsbjerg_569-sl1.jpg"
                  description="Spodsbjerg ist eine kleine Hafenstadt mit weniger als 200 Einwohnern an der Ostküste der dänischen Insel Langeland am Großen Belt (hier auch „Langelandsbelt“ genannt) gelegen. Es gehört zur Langeland Kommune in der Region Syddanmark."
                  rootClassName="place-card-root-class-name1"
                  className="landing-page-component02"
                ></PlaceCard>
              </a>
            </div>
            <a
              href="https://en.spodsbjerghavn.dk/"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link11 button"
            >
              Infos
            </a>
          </div>
          <div className="landing-page-container05">
            <a
              href="https://redtownbedandexcursions.dk/en/prices-house-no-14/12"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link12"
            >
              <PlaceCard
                city="Rødbyhavn"
                image="https://gdkfiles.visitdenmark.com/files/447/447_205065.jpg?width=987"
                description="Rødbyhavn ist ein dänischer Fährhafen am Fehmarnbelt und eine Ortschaft auf der Insel Lolland. Der als Hafen von Rødby gegründete Ort ist Teil der Lolland Kommune in der Region Sjælland.  Er liegt knapp fünf Kilometer südlich von Rødby und rund 20 Kilometer südlich vom Sitz der Lolland Kommune in Maribo."
                rootClassName="place-card-root-class-name2"
                className="landing-page-component03"
              ></PlaceCard>
            </a>
            <a
              href="https://de.wikipedia.org/wiki/R%C3%B8dbyhavn"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link13 button"
            >
              Infos
            </a>
          </div>
          <div className="landing-page-container06">
            <a
              href="https://staldenscafe.dk/bed-breakfast"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link14"
            >
              <PlaceCard
                city="Bogø"
                image="https://hhweb.de/wp-content/uploads/2017/06/Seeland-Br%C3%BCcke.jpg"
                description="Bogø ist eine dänische Insel in der Ostsee. Sie liegt zwischen Falster und Seeland westlich von Møn. Die Insel ist etwa 7 km lang, 3 km breit und hat eine Fläche von 13,07 km². Die maximale Höhe über dem Meeresspiegel beträgt 32 m."
                rootClassName="place-card-root-class-name3"
                className="landing-page-component04"
              ></PlaceCard>
            </a>
            <a
              href="https://skandinavien.eu/daenemark/inseln/bogoe.html"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link15 button"
            >
              Infos
            </a>
          </div>
          <div className="landing-page-container07">
            <a
              href="http://www.praesto-camping.dk/hyttepriser.html"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link16"
            >
              <PlaceCard
                city="Præstø"
                image="https://upload.wikimedia.org/wikipedia/commons/5/53/Adelgade%2C_Pr%C3%A6st%C3%B8_esb_01.jpg"
                description="Præstø ist eine dänische Stadt der Gemeinde Vordingborg in der Region Sjælland mit 3857 Einwohnern. Östlich erstreckt sich die Halbinsel Præstø Næb."
                rootClassName="place-card-root-class-name4"
                className="landing-page-component05"
              ></PlaceCard>
            </a>
            <a
              href="https://www.tripadvisor.de/Attractions-g1931733-Activities-Praestoe_Vordingborg_Municipality_South_Zealand_Zealand.html"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link17 button"
            >
              Infos
            </a>
          </div>
          <div className="landing-page-container08">
            <a
              href="https://www.koege-bnb.dk/vi-tilbyder/vaerelser/"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link18"
            >
              <PlaceCard
                city=" Køge"
                image="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/K%C3%B8ge_-_Kirkestr%C3%A6de.jpg/1280px-K%C3%B8ge_-_Kirkestr%C3%A6de.jpg"
                description="Køge ist eine seeländische Hafenstadt an der Køge Bugt. Administrativ gehört sie zur dänischen Region Sjælland und ist Zentrum der gleichnamigen Køge Kommune mit 61.718 Einwohnern."
                rootClassName="place-card-root-class-name5"
                className="landing-page-component06"
              ></PlaceCard>
            </a>
            <a
              href="https://www.tripadvisor.de/Attractions-g230032-Activities-Koege_Koege_Municipality_South_Zealand_Zealand.html"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link19 button"
            >
              Infos
            </a>
          </div>
          <div className="landing-page-container09">
            <a
              href="https://cityhub.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link20"
            >
              <PlaceCard
                city="København"
                image="https://www.planet-wissen.de/kultur/metropolen/kopenhagen/ausflugsbootinkopenhagenshafenviertelnyhavn100~_v-gseagaleriexl.jpg"
                description="Kopenhagen ist die Hauptstadt Dänemarks und das kulturelle und wirtschaftliche Zentrum des Landes (Primatstadt). Die Stadt ist Sitz von Parlament (Folketing), höchstem Gericht (Højesteret), Regierung sowie Residenz der dänischen Königin Margrethe II."
                rootClassName="place-card-root-class-name6"
                className="landing-page-component07"
              ></PlaceCard>
            </a>
            <a
              href="https://www.visitdenmark.de/daenemark/regionen/kopenhagen"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link21 button"
            >
              Infos
            </a>
          </div>
        </div>
        <div className="landing-page-container10"></div>
        <h1 id="faehre" className="landing-page-text30">
          <br></br>
          <span>
            Fähren
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h1>
        <div className="landing-page-faehren-container">
          <div className="landing-page-container11">
            <a
              href="https://aeroe-ferry.teambooking.dk/new-booking/?booking=JTdCJTIydGlja2V0cyUyMiUzQSU1QiU3QiU3RCU1RCUyQyUyMmFza0ZvckZsb3clMjIlM0F0cnVlJTdE&amp;lang=de"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link22"
            >
              <PlaceCard
                city="1. Fynshav → Søby"
                image="/playground_assets/f%C3%A4hre1-300h.jpg"
                description=" "
                className="landing-page-component08"
              ></PlaceCard>
            </a>
          </div>
          <div className="landing-page-container12">
            <div className="landing-page-container13">
              <a
                href="https://aeroe-ferry.teambooking.dk/new-booking?lang=de"
                target="_blank"
                rel="noreferrer noopener"
                className="landing-page-link23"
              >
                <PlaceCard
                  city="2. Ærøskøbing → Svendborg"
                  image="/playground_assets/f%C3%A4hre2-300h.jpg"
                  description=" "
                  className="landing-page-component09"
                ></PlaceCard>
              </a>
            </div>
          </div>
          <div className="landing-page-container14">
            <a
              href="https://www.langelandslinjen.de/fahrplan"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link24"
            >
              <PlaceCard
                city="3. Spodsbjerg → Tårs"
                image="/playground_assets/f%C3%A4hre3-300h.jpg"
                description=" "
                className="landing-page-component10"
              ></PlaceCard>
            </a>
          </div>
          <div className="landing-page-container15">
            <a
              href="https://www.bogoe-stubbekoebing.dk/fahrkarten"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link25"
            >
              <PlaceCard
                city="4. Stubbekøbing → Bogø"
                image="/playground_assets/f%C3%A4hre4-300h.jpg"
                description=" "
                className="landing-page-component11"
              ></PlaceCard>
            </a>
          </div>
        </div>
        <div className="landing-page-container16"></div>
        <h1 id="anfahrt" className="landing-page-text33">
          <br></br>
          <span>Anfahrt und Rückreise</span>
        </h1>
        <div className="landing-page-an-und-abreise">
          <a
            href="https://www.tripadvisor.de/Attractions-g187331-Activities-Hamburg.html"
            target="_blank"
            rel="noreferrer noopener"
            className="landing-page-link26"
          >
            <PlaceCard
              city="Anfahrt: Hamburg"
              image="https://a.cdn-hotels.com/gdcs/production57/d830/70f985c4-c6b3-4700-a11f-e7289de58ae5.jpg?impolicy=fcrop&amp;w=800&amp;h=533&amp;q=medium"
              description=" Von München nach Hamburg ca. 8 Std. Autofahrt"
              className="landing-page-component12"
            ></PlaceCard>
          </a>
          <div className="landing-page-container17">
            <a
              href="https://www.google.de/maps/place/Parkplatz+Eckenerstra%C3%9Fe/@54.7936555,9.4173955,16z/data=!4m8!1m2!2m1!1sparkplatz+nikolaiallee+flensburg!3m4!1s0x47b343b50361c00f:0x5c15a69456631636!8m2!3d54.7959673!4d9.4203249"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link27"
            >
              <PlaceCard
                city="Parkplatz in Flensburg"
                image="/playground_assets/parkenflensburg-300h.jpg"
                description=" "
                className="landing-page-component13"
              ></PlaceCard>
            </a>
          </div>
          <div className="landing-page-container18">
            <a
              href="https://www.thetrainline.com/de"
              target="_blank"
              rel="noreferrer noopener"
              className="landing-page-link28"
            >
              <PlaceCard
                city="Zugverbindung Kopenhagen → Flensburg"
                image="/playground_assets/zug-300h.jpg"
                description=" Dauer: ca. 3 Std 10 min"
                className="landing-page-component14"
              ></PlaceCard>
            </a>
          </div>
          <a
            href="https://www.tripadvisor.de/Attractions-g187351-Activities-Hannover_Lower_Saxony.html"
            target="_blank"
            rel="noreferrer noopener"
            className="landing-page-link29"
          >
            <PlaceCard
              city="Rückfahrt: Hannover"
              image="https://www.ndr.de/ratgeber/reise/hannover/hannover14028_v-fullhd.jpg"
              description=" Von Hannover nach München ca. 6 Std. 30 min Autofahrt"
              className="landing-page-component15"
            ></PlaceCard>
          </a>
        </div>
      </div>
      <div className="landing-page-footer">
        <div className="landing-page-menu">
          <h1>SchnubbiTravel</h1>
          <div className="landing-page-follow-container1">
            <span className="landing-page-text36">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <div className="landing-page-icons-container1">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="landing-page-link30"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="landing-page-icon11"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
